<template>
    <div>
        <v-app-bar
            app
            color="white"
            clipped-left
        >
            <v-app-bar-nav-icon
                @click="drawer = !drawer"
                v-if="authenticated"
            ><v-icon>fal fa-bars</v-icon></v-app-bar-nav-icon>
            <router-link
                :to="{name: 'dashboard'}"
                class="title ml-3 mr-5 tw-hidden md:tw-block tw-no-underline d-flex align-center"
            ><img :src="meta[domain].logo" height="36" /></router-link>
            <v-spacer></v-spacer>
            <app-bar-avatar v-if="authenticated" />
        </v-app-bar>
        <v-navigation-drawer
            v-if="authenticated"
            v-model="drawer"
            app
            clipped
            color="secondary"
            dark
        >
            <v-list dense>
                <template v-for="(item) in top">
                    <component
                        :key="item.name"
                        :is="item.meta.menuItem"
                        v-if="item.meta.menuItem"
                    />
                    <template v-else>
                        <v-list-group
                            v-if="item.children && item.children.length > 0"
                            :key="item.meta.label"
                            no-action
                            color="white"
                            :value="$route.meta.parent === item.meta.name"
                            active-class="black"
                        >
                            <template v-slot:activator>
                            <v-list-item-title>{{ item.meta.label }}</v-list-item-title>
                            </template>
                            <v-icon slot="prependIcon" dense>{{ item.meta.icon }}</v-icon>
                            <v-list-item
                                v-for="(child) in item.children"
                                :key="child.name"
                                exact
                                :to="child.to || { name: child.name }"
                                active-class="grey darken-4"
                            >
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ child.meta.label }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>
                        <v-list-item
                            v-else
                            :key="item.name"
                            exact
                            :to="{name: item.name}"
                            active-class="black"
                        >
                            <v-list-item-icon>
                                <v-icon dense>{{ item.meta.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ item.meta.label }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </template>
            </v-list>
            <template v-slot:append>
                <v-list dense>
                    <v-list-item
                        v-for="(item, i) in bottom"
                        :key="i"
                        exact
                        :to="{name: item.children ? item.children[0].name : item.name}"
                    >
                        <v-list-item-icon>
                            <v-icon dense>{{ item.meta.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.meta.label }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </template>
        </v-navigation-drawer>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Http from '@/utils/httpClient';
    import AppBarAvatar from '@/components/AppBarAvatar.vue';

    export default {
        components: {
            AppBarAvatar,
        },
        data() {
            return {
                drawer: null,
                mini: false,
                additionalRoutes: [],
            }
        },
        computed: {
            ...mapGetters(['authenticated', 'me', 'meta', 'domain']),
            top() {
                return this.filter(this.$router.getRoutes(), 'sidebar-top')
                        .map((route) => {
                            route.children = this.getRouteChildren(route, 'sidebar')

                            if (route.name === 'security') {
                                route.children = this.additionalRoutes;
                            }

                            return route;
                        });
            },
            bottom() {
                return this.filter(this.$router.options.routes, 'sidebar-bottom')
                        .map((route) => {
                            if (route.children) {
                                route.children = this.filter(route.children, 'sidebar')
                            }

                            return route;
                        });
            },
        },
        mounted() {
            this.getRSS();
        },
        methods: {
            getRSS() {
                Http.get('rss')
                    .then(({ data }) => {
                        this.additionalRoutes = data.data.map((rss) => ({
                            name: rss.name,
                            to: {
                                name: 'security.rss.index',
                                params: {
                                    rssId: rss.id,
                                }
                            },
                            meta: {
                                label: rss.title,
                                permission: 'manage-customer-security',
                            },
                        }));
                    })
            },
            filter(routes, location) {
                return routes.filter((route) => {
                    if (location && route.meta.location !== location) {
                        return false;
                    }

                    if (typeof route.meta.permission === 'undefined') {
                        return true;
                    }

                    return this.me.permissions.map((p) => p.name).includes(route.meta.permission);
                });
            },
            getRouteChildren(parent) {
                return this.$router.getRoutes().filter((route) => route.parent && route.parent.name === parent.name);
            },
        }
    }
</script>
