import Vue from 'vue';
import Vuex from 'vuex';
import ticket from '@/views/ticket/store';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        ticket,
    },
    state: {
        ordering: {
            nbn: {
                address: 'no address',
                nbnLocationId: 'LOC1234'
            }
        },
        me: {},
        authenticated: false,
        meta: {
            'cynergic.com.au': {
                name: 'Cynergic',
                logo: '/img/logos/cynergic.png',
            },
            'dev.cynergic.com.au': {
                name: 'Cynergic',
                logo: '/img/logos/cynergic.png',
            },
            'cynergic.net': {
                name: 'Cynergic',
                logo: '/img/logos/cynergic.png',
            },
            'multiline.com.au': {
                name: 'Multiline',
                logo: '/img/logos/multiline.png',
            },
            'metatech.com.au': {
                name: 'Metatech',
                logo: '/img/logos/metatech.png',
            },
            'cologic.com.au': {
                name: 'Cologic',
                logo: '/img/logos/cologic.jpg',
            },
            'cynergic.test': {
                name: 'Cynergic',
                logo: '/img/logos/cynergic.png',
            },
            'dev.metadirector.test': {
                name: 'Cynergic',
                logo: '/img/logos/cynergic.png',
            }

        }
    },
    getters: {
        me: (state) => state.me,
        authenticated: (state) => state.authenticated,
        meta: (state) => state.meta,
        domain: () => {
            const { location } = window;
            const pieces = location.hostname.split('.');

            pieces.shift();

            return pieces.join('.');
        }
    },
    mutations: {
        login(state, me) {
            state.me = me;
            state.authenticated = true;
        },
        logout(state) {
            state.me = {};
            state.authenticated = false;
        },
    },
    actions: {
    },
});
