<template>
    <v-menu offset-y content-class="tw-bg-white">
        <template v-slot:activator="{ on }">
            <v-btn
                icon
                v-on="on"
            >
                <v-avatar 
                    color="blue lighten-1" 
                    size="36"
                >
                    <img :src="me.profile_image_url" />
                </v-avatar>
            </v-btn>
        </template>

        <v-list>
            <v-list-item>
                <v-list-item-avatar>
                    <v-avatar 
                        color="blue lighten-1" 
                        size="36"
                    >
                        <img :src="me.profile_image_url" />
                    </v-avatar>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>{{ me.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ me.email }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-divider />
        <v-list>
            <v-list-item
                v-for="item in items"
                :key="item.label"
                exact
                :to="{ name: item.children ? item.children[0].name : item.name }"
            >
                <v-list-item-icon>
                    <v-icon>{{ item.meta.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ item.meta.label }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item @click="logout">
                <v-list-item-icon>
                    <v-icon>fal fa-sign-out-alt fa-fw</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Logout</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import Http from '@/utils/httpClient';

export default {
    computed: {
        ...mapGetters(['me']),
        items() {
            return this.$router.options.routes.filter((route) => {
                if (route.meta.location !== 'avatar') {
                    return false;
                }

                return true;
            });
        }
    },
    methods: {
        logout() {
            this.$root.$loading.open({ text: 'Logging out...' });
            Http.post('logout')
                .then(() => {
                    this.$store.commit('logout');
                    this.$router.push({ name: 'login' }, () => this.$root.$loading.close());
                })
                .catch(() => this.$root.$loading.close());
        }
    }
};
</script>
