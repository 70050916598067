<template>
    <v-snackbar
        top
        :right="right"
        color="white"
        :timeout="options.timeout"
        light
        v-model="snackbar"
    >
        <div class="tw-flex tw-items-center">
            <v-icon dark :color="color" dense>{{ icon }}</v-icon>
            <div class="tw-ml-4">{{ options.text }}</div>
        </div>
        <template v-slot:action="{ attrs }">
            <v-btn
                light
                small
                icon
                v-bind="attrs"
                @click="snackbar = false"
                color="blue-grey lighten-3"
            >
                <v-icon dense>fal fa-times-circle fa-fw</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    data: () => ({
        snackbar: false,
        options: {
            text: null,
            type: null,
            timeout: 4000
        }
    }),
    computed: {
        icon() {
            if (this.options.type === 'success') {
                return 'fal fa-check-circle fa-fw';
            }

            if (this.options.type === 'info') {
                return 'fal fa-info-circle fa-fw';
            }

            return 'fal fa-exclamation-triangle fa-fw';
        },
        right() {
            if (this.options.type === 'success' || this.options.type === 'info') {
                return true;
            }

            return false;
        },
        color() {
            if (this.options.type === 'success') {
                return 'green';
            }

            if (this.options.type === 'info') {
                return 'orange';
            }

            return 'error';
        }
    },
    methods: {
        open(options) {
            this.snackbar = true;

            if (typeof options.timeout === 'undefined') {
                this.options.timeout = 4000;
            }

            this.options = Object.assign(this.options, options);
        },
        close() {
            this.snackbar = false;
        }
    }
};
</script>
