import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import VueCurrencyInput from 'vue-currency-input';
import { setupInterceptors } from './utils/httpClient';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './scss/app.scss';

Vue.use(VueCompositionAPI);

Vue.config.productionTip = false;

Vue.use(VueCurrencyInput, {
    globalOptions: {
        currency: 'USD',
        locale: 'en',
        distractionFree: false,
        valueRange: { min: 0 },
        precision: 2,
        autoDecimalMode: true,
    }
})

Vue.prototype.$slugify = (text, separator = '_') => text.toString().toLowerCase()
        .replace(/\s+/g, separator) // Replace spaces with _
        .replace(/&/g, `${separator}and${separator}`) // Replace & with 'and'
        .replace(/[^\w_.-]+/g, '') // Remove all non-word chars
        .replace(/__+/g, '_'); // Replace multiple _ with single _

const app = new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount('#app');

setupInterceptors(app);
