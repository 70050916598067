<template>
    <v-dialog
        v-model="dialog"
        :max-width="width"
        :style="{ zIndex: zIndex }"
        @keydown.esc="cancel"
        persistent
    >
        <v-card>
            <v-card-title>{{ title }}</v-card-title>
            <v-card-text v-show="!!message">{{ message }}</v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn @click.native="cancel" text class="mr-2">Cancel</v-btn>
                <v-btn dark text :color="agreeButtonColor" @click.native="agree">
                    {{ agreeButtonText }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data: () => ({
        dialog: false,
        resolve: null,
        reject: null,
        message: '',
        title: '',
        color: 'primary',
        width: 400,
        zIndex: 200,
        agreeButtonText: 'Yes',
        agreeButtonColor: 'red'
    }),
    methods: {
        open(options) {
            this.dialog = true;

            Object.keys(options).forEach((key) => {
                this[key] = options[key];
            });

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        agree() {
            this.resolve();
            this.dialog = false;
        },
        cancel() {
            this.reject();
            this.dialog = false;
        }
    }
};
</script>
