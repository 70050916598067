<template>
    <v-dialog v-model="dialog" persistent width="300">
        <v-card color="primary" dark>
            <v-card-text class="pt-3">
                {{ options.text }}
                <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data: () => ({
        dialog: false,
        options: {
            text: null
        }
    }),
    methods: {
        open(options) {
            this.dialog = true;

            if (!options) {
                this.options = { text: 'Loading please wait...' };
            } else {
                this.options = Object.assign(this.options, options);
            }
        },
        close() {
            this.dialog = false;
        }
    }
};
</script>
